import * as React from 'react';
import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useTenant } from '../../shared/Context';

interface DatosBancariosFormProps {
    datosBancarios: {
        banco: number;
        tipoCuenta: number;
        cuenta: string;
        certificacionBancaria: string
    };
    datosBancarios2: {
        banco: number;
        tipoCuenta: number;
        cuenta: string;
        certificacionBancaria: string
    };
    datosBancarios3: {
        banco: number;
        tipoCuenta: number;
        cuenta: string;
        certificacionBancaria: string
    };
    setDatosBancarios: React.Dispatch<React.SetStateAction<{
        banco: number;
        tipoCuenta: number;
        cuenta: string;
        certificacionBancaria: string
    }>>;
    setDatosBancarios2: React.Dispatch<React.SetStateAction<{
        banco: number;
        tipoCuenta: number;
        cuenta: string;
        certificacionBancaria: string
    }>>;
    setDatosBancarios3: React.Dispatch<React.SetStateAction<{
        banco: number;
        tipoCuenta: number;
        cuenta: string;
        certificacionBancaria: string
    }>>;
    idVinculacion: string;
    numeroFormulario: number;
}
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const DatosBancariosForm: React.FC<DatosBancariosFormProps> = ({
    datosBancarios,
    datosBancarios2,
    datosBancarios3,
    setDatosBancarios,
    setDatosBancarios2,
    setDatosBancarios3,
    idVinculacion,
    numeroFormulario }) => {
    const [loadingCertificacionBancaria, setLoadingCertificacionBancaria] = React.useState(false);
    const [loadingCertificacionBancaria2, setLoadingCertificacionBancaria2] = React.useState(false);
    const [loadingCertificacionBancaria3, setLoadingCertificacionBancaria3] = React.useState(false);
  const { tenantId } = useTenant()

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            const nombre = event.target.name;
            setLoadingInputButton(nombre);
            handleSubmitFile(file, nombre);
        }
    };
    const setLoadingInputButton = (fileInputName: string) => {
        switch (fileInputName) {
            case "certificacionBancaria":
                setLoadingCertificacionBancaria(!loadingCertificacionBancaria);
                break;
        }
        switch (fileInputName) {
            case "certificacionBancaria2":
                setLoadingCertificacionBancaria2(!loadingCertificacionBancaria2);
                break;
        }
        switch (fileInputName) {
            case "certificacionBancaria3":
                setLoadingCertificacionBancaria3(!loadingCertificacionBancaria3);
                break;
        }
    }
    const handleSubmitFile = (file: File, fileName: string) => {
        if (file) {
            const formData = new FormData();
            formData.append('TenantId', tenantId);
            formData.append('IdVinculacion', idVinculacion);
            formData.append('File', file);
          fetch(process.env.REACT_APP_CORE_LAMBDA_VINCULACIONES_URL + 'cargueDocumento', {
                method: 'POST',
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error al guardar la información');
                    }
                    setLoadingCertificacionBancaria(false);
                    return response.json();
                })
                .then(data => {
                    if (data.url) {
                        setInfoFileUploaded(fileName, data.url);
                    }
                    // console.log('Información guardada correctamente:', data.url);
                })
                .catch(error => {
                    // console.error('Error al guardar la información:', error);
                })
                .finally(() => {

                });
        }
    };
    const setInfoFileUploaded = (fileName: string, url: string) => {
        switch (fileName) {
            case "certificacionBancaria":
                setDatosBancarios({ ...datosBancarios, certificacionBancaria: url as string })
                break;
            case "certificacionBancaria2":
                setDatosBancarios2({ ...datosBancarios2, certificacionBancaria: url as string })
                break;
            case "certificacionBancaria3":
                setDatosBancarios3({ ...datosBancarios3, certificacionBancaria: url as string })
                break;
        }
    }
    const handleViewDocument = (documentKey: string) => {
      fetch(process.env.REACT_APP_CORE_LAMBDA_VINCULACIONES_URL + 'obtengaDocumento', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                tenantId: tenantId,
                documentKey: documentKey
            })
        }).then(async (res) => {
            if (!res.ok) {
                throw new Error('Error al guardar la información');
            }
            let response = await res.json()
            window.open(response.signedUrl, "_blank", "noreferrer")
        })
    }
    const Formulario1 = () => {
        return (
            <>
                <LoadingButton
                    component="label"
                    variant="outlined"
                    loading={loadingCertificacionBancaria}
                    startIcon={<CloudUploadIcon />}
                    size='small'
                    sx={{ margin: '0 2px' }}
                >
                    Certificación bancaria
                    <VisuallyHiddenInput
                        type="file"
                        accept=".pdf"
                        name="certificacionBancaria"
                        onChange={handleFileChange} />
                </LoadingButton>
                {
                    datosBancarios.certificacionBancaria && (
                        <LoadingButton
                            component="a"
                            onClick={() => handleViewDocument(datosBancarios.certificacionBancaria)}
                            target="_blank"
                            variant="outlined"
                            endIcon={<RemoveRedEyeIcon />}
                            size={'small'}
                            sx={{ margin: '0 2px' }}
                        >
                            Ver
                        </LoadingButton>
                    )}
            </>
        );
    }
    const Formulario2 = () => {
        return (
            <>

                <LoadingButton
                    component="label"
                    variant="outlined"
                    loading={loadingCertificacionBancaria2}
                    startIcon={<CloudUploadIcon />}
                    size='small'
                    sx={{ margin: '0 2px' }}
                >
                    Certificación bancaria
                    <VisuallyHiddenInput
                        type="file"
                        accept=".pdf"
                        name="certificacionBancaria2"
                        onChange={handleFileChange} />
                </LoadingButton>
                {datosBancarios2.certificacionBancaria && (
                    <LoadingButton
                        component="a"
                        onClick={() => handleViewDocument(datosBancarios2.certificacionBancaria)}
                        target="_blank"
                        variant="outlined"
                        endIcon={<RemoveRedEyeIcon />}
                        size={'small'}
                        sx={{ margin: '0 2px' }}
                    >
                        Ver
                    </LoadingButton>
                )}
            </>
        );
    }
    const Formulario3 = () => {
        return (
            <>

                <LoadingButton
                    component="label"
                    variant="outlined"
                    loading={loadingCertificacionBancaria3}
                    startIcon={<CloudUploadIcon />}
                    size='small'
                    sx={{ margin: '0 2px' }}
                >
                    Certificación bancaria
                    <VisuallyHiddenInput
                        type="file"
                        accept=".pdf"
                        name="certificacionBancaria3"
                        onChange={handleFileChange} />
                </LoadingButton>
                {datosBancarios3.certificacionBancaria && (
                    <LoadingButton
                        component="a"
                        onClick={() => handleViewDocument(datosBancarios3.certificacionBancaria)}
                        target="_blank"
                        variant="outlined"
                        endIcon={<RemoveRedEyeIcon />}
                        size={'small'}
                        sx={{ margin: '0 2px' }}
                    >
                        Ver
                    </LoadingButton>
                )}
            </>
        );
    }
    return (
        <>
            {(numeroFormulario === 1) && (<Formulario1 />)}
            {(numeroFormulario === 2) && (<Formulario2 />)}
            {(numeroFormulario === 3) && (<Formulario3 />)}
        </>
    );
}

export default DatosBancariosForm;
