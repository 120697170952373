const NombreTipoDocumento: { [key: number]: string } = {
  31: 'NIT',
  13: 'Cédula de ciudadanía',
  11: 'Registro civil',
  12: 'Tarjeta de identidad',
  41: 'Pasaporte',
  91: 'NUIP',
  21: 'Tarjeta de extranjería',
  22: 'Cédula de extranjería',
  47: 'PEP',
  50: 'NIT de otro país',
  42: 'Documento de indentificación extranjero',
}

const siglasTipoDocumento = {
  '11': 'RC',
  '12': 'TI',
  '13': 'CC',
  '21': 'TE',
  '22': 'CE',
  '31': 'NIT',
  '41': 'PA',
  '42': 'DNI',
  '47': 'PEP',
  '50': 'NIT de otro país',
  '91': 'NUIP',
  "100": "PPT"
}

const ListadoBancos = [
  { value: 5, label: 'BANCAFE ' },
  { value: 47, label: 'BANCO MUNDO MUJER' },
  { value: 58, label: 'PROCREDIT COLOMBIA' },
  { value: 61, label: 'BANCOOMEVA' },
  { value: 62, label: 'BANCO FALABELLA' },
  { value: 63, label: 'COOTRAFA COOPERATIVA FINANCIERA' },
  { value: 83, label: 'CFA COOPERATIVA FINANCIERA DE ANTIOQUIA' },
  { value: 84, label: 'BANCO PICHINCHA' },
  { value: 100, label: 'REPUBLICA ' },
  { value: 101, label: 'BANCO DE BOGOTA ' },
  { value: 102, label: 'BANCO POPULAR ' },
  { value: 103, label: 'COLOMBIA ' },
  { value: 104, label: 'HSBC ' },
  { value: 105, label: 'CAFETERO  (NO UTILIZAR NO EXISTE)' },
  { value: 106, label: 'BANCO SANTANDER DE NEGOCIOS CO' },
  { value: 107, label: 'BANCOLOMBIA ' },
  { value: 108, label: 'REAL DE COLOMBIA ' },
  { value: 109, label: 'CITIBANK ' },
  { value: 110, label: 'LLOYDS TSB BANK' },
  { value: 111, label: 'BANCO AGRARIO' },
  { value: 112, label: 'BANCO GNB SUDAMERIS COLOMBIA ' },
  { value: 113, label: 'BBVA ' },
  { value: 114, label: 'HELM - ITAU' },
  { value: 115, label: 'JURISCOOP' },
  { value: 116, label: 'ANDINO ' },
  { value: 118, label: 'NACIONAL DEL COMERCIO ' },
  { value: 119, label: 'COLPATRIA ' },
  { value: 120, label: 'ESTADO ' },
  { value: 121, label: 'UCONAL ' },
  { value: 122, label: 'UNION COLOMBIANO ' },
  { value: 123, label: 'BANCO DE OCCIDENTE' },
  { value: 124, label: 'EXTEBANDES DE COLOMBIA ' },
  { value: 126, label: 'COLOMBO AMERICANO ' },
  { value: 127, label: 'SERVIGIROS' },
  { value: 128, label: 'MERCANTIL DE COLOMBIA ' },
  { value: 129, label: 'TEQUENDAMA ' },
  { value: 130, label: 'BANCOOP ' },
  { value: 131, label: 'BANCOLDEX S.A.' },
  { value: 132, label: 'BANCO CAJA SOCIAL ' },
  { value: 133, label: 'DEL PACIFICO S.A. ' },
  { value: 134, label: 'SUPERIOR ' },
  { value: 135, label: 'INTERCONTINENTAL ' },
  { value: 136, label: 'COOPDESARROLLO - MEGABANCO' },
  { value: 137, label: 'DE BOSTON ' },
  { value: 148, label: 'BANCO ALIADAS' },
  { value: 202, label: 'ANDES -CF' },
  { value: 204, label: 'CORFIBOYACA -CF' },
  { value: 205, label: 'FINANCALDAS -CF' },
  { value: 206, label: 'COLOMBIANA -CF' },
  { value: 209, label: 'CORFIGAN -CF' },
  { value: 210, label: 'CUNDINAMARCA -CF' },
  { value: 211, label: 'VALLE -CF' },
  { value: 215, label: 'IFI.INSTITUTO DE FOMENTO INDUSTRIAL' },
  { value: 216, label: 'INDUFINANCIERA -CF' },
  { value: 217, label: 'CORFES -CF' },
  { value: 218, label: 'CORFINSURA -CF' },
  { value: 219, label: 'CORFINORTE -CF' },
  { value: 220, label: 'BANCO DE OCCIDENTE -CF' },
  { value: 221, label: 'LATINOAMERICANA -CF' },
  { value: 222, label: 'DESARROLLO -CF' },
  { value: 223, label: 'PROGRESO -CF' },
  { value: 226, label: 'CORFINANSA -CF' },
  { value: 228, label: 'CORFITOLIMA -CF' },
  { value: 229, label: 'TRANSPORTE -CF' },
  { value: 231, label: 'CORFIUNION -CF' },
  { value: 235, label: 'CORFIPACIFICO -CF' },
  { value: 292, label: 'CONFIAR COOPERATIVA FINANCIERA ACH' },
  { value: 301, label: 'COLPATRIA -CAV' },
  { value: 303, label: 'CONCASA -CAV' },
  { value: 304, label: 'DAVIVIENDA -CAV' },
  { value: 305, label: 'GRANAHORRAR -CAV' },
  { value: 306, label: 'AHORRAMAS -CAV' },
  { value: 307, label: 'LAS VILLAS (ERRADO) -CAV' },
  { value: 308, label: 'COLMENA -CAV' },
  { value: 309, label: 'CONAVI -CAV' },
  { value: 310, label: 'CORPAVI -CAV' },
  { value: 352, label: 'BANCO AV VILLAS-CAV' },
  { value: 405, label: 'FINANSOL -CAV' },
  { value: 406, label: 'DELTA BOLIVAR -CFC' },
  { value: 407, label: 'BERMUDEZ Y VALENZUELA -CFC' },
  { value: 408, label: 'DIAMANTE -CFC' },
  { value: 409, label: 'FES -CFC' },
  { value: 411, label: 'INVERCREDITO -CFC' },
  { value: 413, label: 'INVERSORA -CFC' },
  { value: 414, label: 'PRONTA -CFC' },
  { value: 417, label: 'COMERCIA -CFC' },
  { value: 419, label: 'PROVENSA -CFC' },
  { value: 421, label: 'MAZDACREDITO -CFC' },
  { value: 422, label: 'CONFINANCIERA -CFC' },
  { value: 423, label: 'SERFINANZA -CFC' },
  { value: 424, label: 'ANDINA -CFC' },
  { value: 425, label: 'FINANSA -CFC' },
  { value: 426, label: 'SUFINANCIAMIENTO -CFC' },
  { value: 429, label: 'CREDINVER -CFC' },
  { value: 431, label: 'G.M.A.C.COLOMBIA -CFC' },
  // { value: 433, label: 'INTERNACIONAL -CFC' },
  { value: 434, label: 'ALIADAS -CFC' },
  { value: 435, label: 'MULTIFINANCIERA -CFC' },
  { value: 437, label: 'FINSOCIAL -CFC' },
  { value: 440, label: 'COLPATRIA -CFC' },
  { value: 442, label: 'CRECER -CFC' },
  { value: 443, label: 'FINEVESA -CFC' },
  { value: 446, label: 'COLTEFINANCIERA -CFC' },
  { value: 456, label: 'SELFINCO -CFC' },
  { value: 457, label: 'FORTALEZA S.A. -CFC' },
  { value: 458, label: 'LEASING MAZDA S.A. -CFC' },
  { value: 459, label: 'LEASING DEL VALLE -CFC' },
  { value: 460, label: 'ANDILEASING -CFC' },
  { value: 461, label: 'SELFIN LEASING -CFC' },
  { value: 462, label: 'LEASING ARFIN -CFC' },
  { value: 463, label: 'LEASING COLVALORES S.A. -CFC' },
  { value: 464, label: 'PROGRESO LEASING -CFC' },
  { value: 465, label: 'LEASING BOLIVAR -CFC' },
  { value: 466, label: 'LEASING FENIX -CFC' },
  { value: 467, label: 'LEASING GRANCOLOMBIANA -CFC' },
  { value: 468, label: 'LEASING PACIFICO -CFC' },
  { value: 469, label: 'LEASING COLPATRIA -CFC' },
  { value: 470, label: 'LEASING ALIADAS -CFC' },
  { value: 471, label: 'LEASING COLMENA S.A. -CFC' },
  { value: 472, label: 'DESARROLLO S.A. -CFC' },
  { value: 473, label: 'CAPITAL S.A. -CFC' },
  { value: 474, label: 'CITIBANK S.A. -CFC' },
  { value: 475, label: 'BOGOTA S.A. -CFC' },
  { value: 476, label: 'SULEASING S.A. -CFC' },
  { value: 477, label: 'FES LEASING S.A. -CFC' },
  { value: 478, label: 'EQUILEASING S.A. -CFC' },
  { value: 479, label: 'EUROLEASING S.A. -CFC' },
  { value: 480, label: 'UNION S.A. -CFC' },
  { value: 481, label: 'COMERCIO S.A. -CFC' },
  { value: 482, label: 'GANADERO S.A. -CFC' },
  { value: 483, label: 'FINANLEASING S.A. -CFC' },
  { value: 484, label: 'PORVENIR S.A. -CFC' },
  { value: 485, label: 'BOYACA S.A. -CFC' },
  { value: 486, label: 'PATRIMONIO S.A. -CFC' },
  { value: 487, label: 'CREDITO S.A. -CFC' },
  { value: 488, label: 'LA ANDINA S.A. -CFC' },
  { value: 489, label: 'DEPOSITANTE DECEVAL' },
  { value: 490, label: 'BANCO DE OCCIDENTE-CFC' },
  { value: 491, label: 'TRANSLEASING S.A. -CFC' },
  { value: 492, label: 'MUNDIAL S.A. -CFC' },
  { value: 493, label: 'TODOLEASING S.A. -CFC' },
  { value: 494, label: 'LEASING CALI -CFC' },
  { value: 495, label: 'CALDAS -CFC' },
  // { value: 496, label: 'INTERNACIONAL -CFC' },
  { value: 497, label: 'BANCO POPULAR -CFC' },
  { value: 498, label: 'MULTILEASING -CFC' },
  { value: 499, label: 'LEASING SANTANDER S.A. -CFC' },
  { value: 501, label: 'BERMUDEZ Y VALENZUELA -SF' },
  { value: 503, label: 'FIDUGAN -SF' },
  { value: 504, label: 'FIDUCIARIA BCA -SF' },
  { value: 506, label: 'COLMENA -SF' },
  { value: 507, label: 'FIDUSKANDIA -SF' },
  { value: 508, label: 'COLFIDES S.A. -SF' },
  { value: 511, label: 'CALDAS -SF' },
  { value: 512, label: 'PREVISORA -SF' },
  { value: 513, label: 'GRANFIDUCIARIA -SF' },
  { value: 514, label: 'CACERES Y FERRO -SF' },
  { value: 515, label: 'FIDUCOR -SF' },
  { value: 516, label: 'ALIANZA -SF' },
  { value: 517, label: 'SUFIDUCIA -SF' },
  { value: 518, label: 'BANCO POPULAR -SF' },
  { value: 519, label: 'FIDUCAFE -SF' },
  { value: 520, label: 'FIDUVALLE -SF' },
  { value: 521, label: 'BANCO DE OCCIDENTE -SF' },
  { value: 522, label: 'BOGOTA -SF' },
  { value: 523, label: 'FIDUCREDITO -SF' },
  { value: 524, label: 'CITITRUST S.A. -SF' },
  { value: 525, label: 'COLPATRIA -SF' },
  { value: 526, label: 'FIDUCOMERCIO -SF' },
  { value: 527, label: 'FIDUANGLO -SF' },
  { value: 528, label: 'FIDUBIC -SF' },
  { value: 529, label: 'FIDUBANDES -SF' },
  { value: 530, label: 'UNION -SF' },
  { value: 531, label: 'FIDUCOLOMBIA -SF' },
  { value: 532, label: 'ESTADO -SF' },
  { value: 533, label: 'FIDUFES -SF' },
  { value: 534, label: 'TEQUENDAMA -SF' },
  { value: 535, label: 'FIDUCOOP -SF' },
  { value: 536, label: 'ANDINO -SF' },
  { value: 537, label: 'GLOBAL -SF' },
  { value: 538, label: 'FIDUCENTRAL -SF' },
  { value: 539, label: 'FIDUAGRARIA -SF' },
  { value: 540, label: 'FIDUCOLDEX -SF' },
  { value: 541, label: 'ULTRA -SF' },
  { value: 542, label: 'DAVIVIENDA -SF' },
  { value: 543, label: 'FIDUAMERIS -SF' },
  // { value: 544, label: 'BANCO SANTANDER DE NEGOCIOS CO-SF' },
  { value: 545, label: 'SELFIDUCIA -SF' },
  { value: 546, label: 'FIDUIFI -SF' },
  { value: 547, label: 'FIDUEMPRESA -SF' },
  { value: 601, label: 'ALMABANCO -AGD' },
  { value: 602, label: 'ALMACENAR -AGD' },
  { value: 603, label: 'ALMADELCO -AGD' },
  { value: 604, label: 'ALMAGRAN -AGD' },
  { value: 605, label: 'ALMAVIVA -AGD' },
  { value: 606, label: 'ALPOPULAR -AGD' },
  { value: 607, label: 'ALOCCIDENTE -AGD' },
  { value: 608, label: 'ALMAGRARIO -AGD' },
  { value: 609, label: 'ALMABIC -AGD' },
  { value: 612, label: 'ALMACAFE -AGD' },
  { value: 613, label: 'ALCALDAS -AGD' },
  { value: 802, label: 'COOPCENTRAL -OCF' },
  { value: 805, label: 'COOPDESARROLLO -OCF' },
  { value: 824, label: 'CODEMA COOPERATIVA MAGISTERIO' },
  { value: 901, label: 'ALLIANZ SEGUROS -SR' },
  { value: 903, label: 'COLOMBIA -SR' },
  { value: 904, label: 'HEMISFERICA -SR' },
  { value: 1001, label: 'AURORA -SC' },
  { value: 1002, label: 'BOLIVAR -SC' },
  { value: 1003, label: 'COLMENA -SC' },
  { value: 1004, label: 'ALLIANZ SEGUROS -SC' },
  { value: 1005, label: 'GRANCOLOMBIANA -SC' },
  { value: 1006, label: 'NACIONAL -SC' },
  { value: 1007, label: 'COLPATRIA -SC' },
  { value: 1008, label: 'CUMPLIMIENTO SURAMERICANA -SC' },
  { value: 1009, label: 'CUMPLIMIENTO CONFIANZA    -SC' },
  { value: 1053, label: 'BANCO W S.A.' },
  { value: 1059, label: 'BANCAMIA S.A.' },
  { value: 1063, label: 'BANCO FINANDINA S.A.' },
  // { value: 1065, label: 'BANCO SANTANDER DE NEGOCIOS CO' },
  { value: 1066, label: 'BANCO COOPERATIVO COOPCENTRAL' },
  { value: 1067, label: 'MI BANCO S.A.' },
  { value: 1070, label: 'LULO BANK' },
  { value: 1071, label: 'BANCO J.P. MORGAN COLOMBIA S.A.' },
  { value: 1151, label: 'RAPPIPAY' },
  { value: 1291, label: 'COOFINEP COOPERATIVA FINANCIERA' },
  { value: 1301, label: 'ALLIANZ SEGUROS -SG' },
  { value: 1302, label: 'ROYAL & SUNALLIANCE -SG' },
  { value: 1303, label: 'GRANCOLOMBIANA -SG' },
  { value: 1304, label: 'CAJA AGRARIA -SG' },
  { value: 1305, label: 'CIGNA -SG' },
  { value: 1306, label: 'COLPATRIA -SG' },
  { value: 1307, label: 'AGRICOLA -SG' },
  { value: 1308, label: 'CONFIANZA -SG' },
  { value: 1309, label: 'CENTRAL DE SEGUROS -SG' },
  { value: 1310, label: 'ANTORCHA -SG' },
  { value: 1311, label: 'ATLAS -SG' },
  { value: 1312, label: 'COLMENA -SG' },
  { value: 1313, label: 'AURORA -SG' },
  { value: 1314, label: 'ANDINA -SG' },
  { value: 1315, label: 'FENIX -SG' },
  { value: 1316, label: 'GRANADINA -SG' },
  { value: 1317, label: 'MUNDIAL -SG' },
  { value: 1318, label: 'SURAMERICANA -SG' },
  { value: 1319, label: 'CONDOR -SG' },
  { value: 1320, label: 'LIBERTADOR -SG' },
  { value: 1321, label: 'CHUBB DE COLOMBIA -SG' },
  { value: 1322, label: 'INTERAMERICANA -SG' },
  { value: 1323, label: 'NACIONAL -SG' },
  { value: 1324, label: 'PREVISORA -SG' },
  { value: 1325, label: 'ALFA -SG' },
  { value: 1326, label: 'CARIBE -SG' },
  { value: 1327, label: 'BOLIVAR -SG' },
  { value: 1328, label: 'LATINOAMERICANA -SG' },
  { value: 1329, label: 'ESTADO -SG' },
  { value: 1330, label: 'SEGUREXPO -SG' },
  { value: 1331, label: 'TEQUENDAMA -SG' },
  { value: 1332, label: 'GENERALI COLOMBIA -SG' },
  { value: 1333, label: 'SKANDIA -SG' },
  { value: 1334, label: 'MAPFRE -SG' },
  { value: 1335, label: 'LIBERTY SEGUROS  S.A.  -SG' },
  { value: 1336, label: 'SEGUROS LA GANADERA -SG' },
  { value: 1337, label: 'ACE SEGUROS -SG' },
  { value: 1338, label: 'SBS SEGUROS' },
  { value: 1339, label: 'ASEGURADORA SOLIDARIA DE COLOMBIA -SG' },
  { value: 1340, label: 'FONDO DE AUXILIO MUTUO FLOTA USAQUEN -SG' },
  { value: 1341, label: 'FONDO DE AYUDA MUTUA TRANSPORTES PENSILVANIA -SG' },
  { value: 1342, label: 'FONDO DE AYUDA MUTUA ISGO -SG' },
  { value: 1343, label: 'BBVA -SG' },
  { value: 1344, label: 'UNION COMERCIAL DE TRANSPORTE S.A. -SG' },
  { value: 1345, label: 'FONDO DE AYUDA MUTUA TRANSPORTES FLOTA BLANCA -SG' },
  { value: 1346, label: 'TRANSPORTES URBANOS SAMPER MENDOZA BUS BLANCOS -SG' },
  { value: 1347, label: 'EMPRESA VECINAL DE TRANSPORTES DE SUBA -SG' },
  { value: 1348, label: 'COOPERATIVA INTEGRAL COCEVES LTDA. -SG' },
  { value: 1349, label: 'COOPERATIVA DE TRANSPORTES LA FLORIDA LTDA. -SG' },
  { value: 1350, label: 'FONDO DE AYUDA MUTUA TRANSPORTES UNIVERSO -SG' },
  { value: 1351, label: 'FONDO DE AYUDA MUTUA COOP.INTEG.TRANSP.OMEGA -SG' },
  { value: 1352, label: 'FONDO DE AYUDA COOP.CONTINENTAL.TRANSP.LTDA  -SG' },
  { value: 1353, label: 'FONDO DE AYUDA EXPRESO GAVIOTA S.A. -SG' },
  { value: 1354, label: 'FONDO MUTUO DE AUXILIO BARSA -SG' },
  { value: 1355, label: 'FONDO DE SOLIDARIDAD  COOTRANSABASTOS LTDA -SG' },
  { value: 1356, label: 'FONDO DE EXPRESO BOLIVARIANO S.A. -SG' },
  { value: 1357, label: 'FONDO MUTUO COONATRA -SG' },
  { value: 1358, label: 'FONDO MUTUO SANTRA -SG' },
  { value: 1359, label: 'FONDO COOTRANSBOL -SG' },
  { value: 1360, label: 'COOINTRACONDOR LTDA -SG' },
  { value: 1365, label: 'SOTRANSMIUR S.A. -SG' },
  { value: 1367, label: 'ZURICH COLOMBIA SEGUROS S.A' },
  { value: 1368, label: 'LA EQUIDAD -SG' },
  { value: 1370, label: 'GIROS Y FINANZAS CF' },
  { value: 1401, label: 'ALLIANZ SEGUROS -SV' },
  { value: 1403, label: 'GRANCOLOMBIANA -SV' },
  { value: 1404, label: 'COLPATRIA -SV' },
  { value: 1405, label: 'AGRICOLA -SV' },
  { value: 1406, label: 'ATLAS -SV' },
  { value: 1407, label: 'BOLIVAR -SV' },
  { value: 1408, label: 'AURORA -SV' },
  { value: 1409, label: 'SKANDIA -SV' },
  { value: 1410, label: 'GRANADINA -SV' },
  { value: 1411, label: 'SURAMERICANA -SV' },
  { value: 1413, label: 'INTERAMERICANA -SV' },
  { value: 1414, label: 'NACIONAL -SV' },
  { value: 1415, label: 'MUNDIAL -SV' },
  { value: 1416, label: 'PAN AMERICAN -SV' },
  { value: 1417, label: 'ALFA -SV' },
  { value: 1418, label: 'LATINOAMERICANA -SV' },
  { value: 1419, label: 'ESTADO -SV' },
  { value: 1420, label: 'FENIX -SV' },
  { value: 1423, label: 'TEQUENDAMA -SV' },
  { value: 1501, label: 'EQUIDAD -SCS' },
  { value: 1502, label: 'SOLIDARIA -SCS' },
  { value: 1507, label: 'NEQUI' },
  { value: 1551, label: 'DAVIPLATA' },
  { value: 1558, label: 'BANCO CREDIFINANCIERA S.A.' },
  { value: 1637, label: 'IRIS' },
  { value: 1801, label: 'MOVII' },
  { value: 1802, label: 'DING TECNIPAGOS S.A.' },
  { value: 1804, label: 'UALA BANCAR TECNOLOGIA CO' },
  { value: 1805, label: 'BANCO BTG PACTUAL' },
  { value: 2125, label: 'BANCO CENTRAL HIPOTECARIO H' },
  { value: 2126, label: 'CENTRAL HIPOTECARIO -BANCO' },
  { value: 2127, label: 'CENTRAL HIPOTECARIO -CAV' },
  { value: 2201, label: 'BANCO DEL COMERCIO EXTERIOR' },
  { value: 2202, label: 'FINDETER' },
  { value: 2203, label: 'FINANCIERA ENERGETICA NACIONAL -FEN' },
  { value: 2204, label: 'FINAGRO' },
  { value: 2205, label: 'ICETEX' },
  { value: 2206, label: 'FONADE' },
  { value: 2207, label: 'ASEGURADORA QBE SEGUROS' },
  { value: 18888, label: 'LEASING DESARROLLO -SL' },
];

export { ListadoBancos, NombreTipoDocumento, siglasTipoDocumento };