import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Checkbox, FormControlLabel, Grid, Typography, useMediaQuery } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useEffect } from 'react';
import { PropsDatosAnexos } from '../../shared/VinculacionesModels';
import { GetUrlTenant, useEnv, useTenant } from '../../shared/Context';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AnexosForm: React.FC<PropsDatosAnexos> = ({ datosAnexos, setDatosAnexos, idVinculacion }) => {
  const ambiente = useEnv()
  const {tenantId} = useTenant()
  const url = GetUrlTenant(tenantId, ambiente)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [acceptTerminosCesion, setAccepTerminosCesion] = React.useState(datosAnexos.terminosCesion);
  const handleClickAcceptTerminosCesion = () => {
    setAccepTerminosCesion(!acceptTerminosCesion);
  };
  useEffect(() => {
    getReplaceValues();
  }, [acceptTerminosCesion]);

  const getReplaceValues = () => {
    if (acceptTerminosCesion) {
      setDatosAnexos((prevState: any) => ({
        ...prevState,
        terminosCesion: true
      }));
    } else {
      setDatosAnexos((prevState: any) => ({
        ...prevState,
        terminosCesion: false
      }));
    }
  }
  const [loadingRut, setLoadingRut] = React.useState(false);
  const [loadingComposicionAccionaria, setLoadingComposicionAccionaria] = React.useState(false);
  const [loadingCamaraDeComercio, setLoadingCamaraDeComercio] = React.useState(false);
  const [loadingIdentificacionRepLegal, setLoadingIdentificacionRepLegal] = React.useState(false);
  const [loadingEstadosFinancieros, setLoadingEstadosFinancieros] = React.useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const nombre = event.target.name;
      setLoadingInputButton(nombre);
      handleSubmitFile(file, nombre);
    }
  };
  const setLoadingInputButton = (fileInputName: string) => {
    switch (fileInputName) {
      case "rut":
        setLoadingRut(!loadingRut);
        break;
      case "composicionAccionaria":
        setLoadingComposicionAccionaria(!loadingComposicionAccionaria);
        break;
      case "camaraDeComercio":
        setLoadingCamaraDeComercio(!loadingCamaraDeComercio);
        break;
      case "identificacionRepLegal":
        setLoadingIdentificacionRepLegal(!loadingIdentificacionRepLegal);
        break;
      case "estadosFinancieros":
        setLoadingEstadosFinancieros(!loadingEstadosFinancieros);
        break;
    }
  }
  const handleSubmitFile = (file: File, fileName: string) => {
    if (file) {
      const formData = new FormData();
      formData.append('TenantId', tenantId);
      formData.append('IdVinculacion', idVinculacion);
      formData.append('File', file);
      fetch(process.env.REACT_APP_CORE_LAMBDA_VINCULACIONES_URL + 'cargueDocumento', {
        method: 'POST',
        body: formData
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al guardar la información');
          }
          setLoadingRut(false);
          setLoadingComposicionAccionaria(false);
          setLoadingCamaraDeComercio(false);
          setLoadingIdentificacionRepLegal(false);
          setLoadingEstadosFinancieros(false);
          return response.json();
        })
        .then(data => {
          if (data.url) {
            setInfoFileUploaded(fileName, data.url);
          }
        })
        .catch(error => {
          console.error('Error al guardar la información:', error);
        })
        .finally(() => {

        });
    }
  };
  const setInfoFileUploaded = (fileName: string, url: string) => {
    switch (fileName) {
      case "rut":
        setDatosAnexos({ ...datosAnexos, rut: url as string })
        break;
      case "composicionAccionaria":
        setDatosAnexos({ ...datosAnexos, composicionAccionaria: url as string })
        break;
      case "camaraDeComercio":
        setDatosAnexos({ ...datosAnexos, camaraDeComercio: url as string })
        break;
      case "identificacionRepLegal":
        setDatosAnexos({ ...datosAnexos, identificacionRepLegal: url as string })
        break;
      case "estadosFinancieros":
        setDatosAnexos({ ...datosAnexos, estadosFinancieros: url as string })
        break;
    }
  }

  const handleViewDocument = (documentKey: string) => {
    fetch(process.env.REACT_APP_CORE_LAMBDA_VINCULACIONES_URL + 'obtengaDocumento', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        tenantId: tenantId,
        documentKey: documentKey
      })
    }).then(async (res) => {
      if (!res.ok) {
        throw new Error('Error al guardar la información');
      }
      let response = await res.json()
      window.open(response.signedUrl, "_blank", "noreferrer")
    })
  }
  return (
    <>
      <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
        Documentos
      </Typography>
      <Grid container >
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid item xs={6} sx={{ textAlign: 'left' }}>
                <Typography sx={{ mr: 1 }}>Registro Único Tributario (RUT)</Typography>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ textAlign: 'left', ml: 1 }}>
                  <LoadingButton
                    component="label"
                    variant="outlined"
                    loading={loadingRut}
                  >
                    <CloudUploadIcon />
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf"
                      name="rut"
                      onChange={handleFileChange} />
                  </LoadingButton>
                  {datosAnexos.rut && (
                    <LoadingButton
                      component="a"
                      onClick={() => handleViewDocument(datosAnexos.rut)}
                      target="_blank"
                      variant="outlined"
                      sx={{ ml: !isMobile ? 1 : 0 }}
                      endIcon={<RemoveRedEyeIcon />}
                    >
                      {!isMobile ? "Ver archivo" : "Ver"}
                    </LoadingButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <Typography sx={{ mr: 1 }}>Composición accionaria</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ textAlign: 'left', ml: 1 }}>
                <LoadingButton
                  component="label"
                  variant="outlined"
                  loading={loadingComposicionAccionaria}
                >
                  <CloudUploadIcon />
                  <VisuallyHiddenInput
                    type="file"
                    accept=".pdf"
                    name="composicionAccionaria"
                    onChange={handleFileChange} />
                </LoadingButton>
                {datosAnexos.composicionAccionaria && (
                  <LoadingButton
                    component="a"
                    onClick={() => handleViewDocument(datosAnexos.composicionAccionaria)}
                    target="_blank"
                    variant="outlined"
                    sx={{ ml: !isMobile ? 1 : 0 }}
                    endIcon={<RemoveRedEyeIcon />}
                  >
                    {!isMobile ? "Ver archivo" : "Ver"}
                  </LoadingButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <Typography sx={{ mr: 1 }}>Certificado de cámara de comercio</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ textAlign: 'left', ml: 1 }}>
                <LoadingButton
                  component="label"
                  variant="outlined"
                  loading={loadingCamaraDeComercio}
                >
                  <CloudUploadIcon />
                  <VisuallyHiddenInput
                    type="file"
                    accept=".pdf"
                    name="camaraDeComercio"
                    onChange={handleFileChange} />
                </LoadingButton>
                {datosAnexos.camaraDeComercio && (
                  <LoadingButton
                    component="a"
                    onClick={() => handleViewDocument(datosAnexos.camaraDeComercio)}
                    target="_blank"
                    variant="outlined"
                    sx={{ ml: !isMobile ? 1 : 0 }}
                    endIcon={<RemoveRedEyeIcon />}
                  >
                    {!isMobile ? "Ver archivo" : "Ver"}
                  </LoadingButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <Typography sx={{ mr: 1 }}>Documento de identificación del Rep. Legal</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ textAlign: 'left', ml: 1 }}>
                <LoadingButton
                  component="label"
                  variant="outlined"
                  loading={loadingIdentificacionRepLegal}
                >
                  <CloudUploadIcon />
                  <VisuallyHiddenInput
                    type="file"
                    accept=".pdf"
                    name="identificacionRepLegal"
                    onChange={handleFileChange} />
                </LoadingButton>
                {datosAnexos.identificacionRepLegal && (
                  <LoadingButton
                    component="a"
                    onClick={() => handleViewDocument(datosAnexos.identificacionRepLegal)}
                    target="_blank"
                    variant="outlined"
                    sx={{ ml: !isMobile ? 1 : 0 }}
                    endIcon={<RemoveRedEyeIcon />}
                  >
                    {!isMobile ? "Ver archivo" : "Ver"}
                  </LoadingButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={6} sx={{ textAlign: 'left' }}>
              <Typography sx={{ mr: 1 }}>Estados Financieros</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ textAlign: 'left', ml: 1 }}>
                <LoadingButton
                  component="label"
                  variant="outlined"
                  loading={loadingEstadosFinancieros}
                >
                  <CloudUploadIcon />
                  <VisuallyHiddenInput
                    type="file"
                    accept=".pdf"
                    name="estadosFinancieros"
                    onChange={handleFileChange} />
                </LoadingButton>
                {datosAnexos.estadosFinancieros && (
                  <LoadingButton
                    component="a"
                    onClick={() => handleViewDocument(datosAnexos.estadosFinancieros)}
                    target="_blank"
                    variant="outlined"
                    sx={{ ml: !isMobile ? 1 : 0 }}
                    endIcon={<RemoveRedEyeIcon />}
                  >
                    {!isMobile ? "Ver archivo" : "Ver"}
                  </LoadingButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ justifyContent: 'center', textAlign: 'justify', mt: '3rem' }}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleClickAcceptTerminosCesion}
              value={datosAnexos.terminosCesion}
              checked={acceptTerminosCesion}
              name='acceptTerminosCesion' />
          }
          label={<>Confirmo que he leído, entiendo y acepto la &nbsp;
            <a href={ url+"/terminosycondiciones" } target="_blank" rel="noopener noreferrer" style={{ color: '#2074d4' }}>
              <u>Política de Seguridad, Privacidad y Tratamiento de Datos</u>
            </a>.</>}
        />
      </Box>
    </>
  );
}

export default AnexosForm;