import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Paper, Button, StepConnector, useMediaQuery, useTheme, ThemeProvider, Grid } from '@mui/material';
import DatosEmpresaForm from './datosEmpresaForm';
import DatosUsuarioForm from './datosUsuarioForm';
import DatosRepresentanteLegalForm from './datosRepresentanteLegalForm';
import InputFileUpload from './datosAnexos';
import SendingInformation from './sendingInformation';
import InformationSent from './informationSent';
import ErrorSendingInformation from './errorSendingInformation';
import Alert from '@mui/material/Alert';
import { SaveDataVinculacion } from '../../shared/saveDataVinculaciones';
import { InfoVinculacion } from '../../shared/VinculacionesModels';
import DatosBancariosFormRender from './datosBancariosRender';
import dayjs from 'dayjs';
import { useTenant } from '../../shared/Context';

const steps = [
    'Datos Generales',
    'Representante Legal',
    'Datos Bancarios',
    'Documentos'
];
function generateGUID() {
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export default function VinculacionCliente({ props }: { props: InfoVinculacion | null }) {
    const { data } = props ?? { data: undefined }
  const { tenantId } = useTenant()
    const [activeStep, setActiveStep] = useState(0);
    const [alertaValidacionActiva, setAlertaValidacionActiva] = useState(false);
    const [enunciadoDatosFaltantes, setEnunciadoDatosFaltantes] = useState("");
    const [datosFaltantes, setDatosFaltantes] = useState("");
    const [debeValidarDatos, setDebeValidarDatos] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));
    const isLargeDesktop = useMediaQuery(theme.breakpoints.down('xl'));
    const handleNext = () => {
        const validarDatosValue = validarDatos();
        if (validarDatosValue) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            GuardarInfo();
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setAlertaValidacionActiva(false);
    };
    const [idVinculacion] = useState(generateGUID());
    const [datosEmpresa, setDatosEmpresa] = React.useState({
        razonSocial: data?.datosEmpresa?.razonSocial ?? '',
        fechaConstitucion: data?.datosEmpresa?.fechaConstitucion ?? dayjs(),
        paisOrigen: data?.datosEmpresa?.paisOrigen ?? undefined,
        direccionFisica: data?.datosEmpresa?.direccionFisica ?? '',
        actividadEconomica: data?.datosEmpresa?.actividadEconomica ?? undefined,
        tipoIdentificacion: data?.datosEmpresa?.tipoIdentificacion ?? "31",
        identificacion: data?.datosEmpresa?.identificacion ?? '',
        dv: data?.datosEmpresa?.dv ?? ''
    });
    const [datosPersonales, setDatosPersonales] = React.useState({
        nombres: data?.datosPersonales?.nombres ?? '',
        apellidos: data?.datosPersonales?.apellidos ?? '',
        tipoIdentificacion: data?.datosPersonales?.tipoIdentificacion ?? 13,
        identificacion: data?.datosPersonales?.identificacion ?? '',
        telefono: data?.datosPersonales?.telefono ?? '',
        correo: data?.datosPersonales?.correo ?? ''
    });
    const [datosRepresentanteLegal, setDatosRepresentanteLegal] = React.useState({
        repLegal: data?.datosRepresentanteLegal?.repLegal ?? false,
        nombres: data?.datosRepresentanteLegal?.nombres ?? '',
        apellidos: data?.datosRepresentanteLegal?.apellidos ?? '',
        tipoIdentificacion: data?.datosRepresentanteLegal?.tipoIdentificacion ?? 13,
        identificacion: data?.datosRepresentanteLegal?.identificacion ?? '',
        telefono: data?.datosRepresentanteLegal?.telefono ?? '',
        correo: data?.datosRepresentanteLegal?.correo ?? ''
    });
    const [datosBancarios, setDatosBancarios] = React.useState({
        banco: data?.datosBancarios?.banco ?? 0,
        tipoCuenta: data?.datosBancarios?.tipoCuenta ?? 2,
        cuenta: data?.datosBancarios?.cuenta ?? '',
        certificacionBancaria: data?.datosBancarios?.certificacionBancaria ?? ''
    });
    const [datosBancarios2, setDatosBancarios2] = React.useState({
        banco: data?.datosBancarios2?.banco ?? 0,
        tipoCuenta: data?.datosBancarios2?.tipoCuenta ?? 2,
        cuenta: data?.datosBancarios2?.cuenta ?? '',
        certificacionBancaria: data?.datosBancarios2?.certificacionBancaria ?? ''
    });
    const [datosBancarios3, setDatosBancarios3] = React.useState({
        banco: data?.datosBancarios3?.banco ?? 0,
        tipoCuenta: data?.datosBancarios3?.tipoCuenta ?? 2,
        cuenta: data?.datosBancarios3?.cuenta ?? '',
        certificacionBancaria: data?.datosBancarios3?.certificacionBancaria ?? ''
    });
    const [datosBancariosRenderizados, setDatosBancariosRenderizados] = React.useState(data?.datosBancariosRenderizados ?? 0);
    const [datosAnexos, setDatosAnexos] = React.useState({
        terminosCesion: data?.datosAnexos?.terminosCesion ?? false,
        rut: data?.datosAnexos?.rut ?? '',
        composicionAccionaria: data?.datosAnexos?.composicionAccionaria ?? '',
        camaraDeComercio: data?.datosAnexos?.camaraDeComercio ?? '',
        identificacionRepLegal: data?.datosAnexos?.identificacionRepLegal ?? '',
        estadosFinancieros: data?.datosAnexos?.estadosFinancieros ?? ''
    });
    const formData = {
        idVinculacion,
        datosEmpresa,
        datosPersonales,
        datosRepresentanteLegal,
        datosBancarios,
        datosBancarios2,
        datosBancarios3,
        datosAnexos
    };
    const validarDatos = useCallback(() => {
        const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const datosFaltantes: string[] = [];
        if (activeStep === 0) {
            const correoPersonalValido = regexCorreo.test(datosPersonales.correo);
            if (datosEmpresa.identificacion === "" ||
                (
                    (datosEmpresa.tipoIdentificacion === "31" && datosEmpresa.dv === "") ||
                    dayjs(datosEmpresa.fechaConstitucion).format('DD-MM-YYYY') === dayjs().startOf('day').format('DD-MM-YYYY') ||
                    datosEmpresa.paisOrigen === "" || datosEmpresa.paisOrigen === undefined ||
                    datosEmpresa.actividadEconomica === "" || datosEmpresa.actividadEconomica === undefined ||
                    datosEmpresa.direccionFisica === "" || datosPersonales.nombres === "" || 
                    datosPersonales.apellidos === "" || datosPersonales.identificacion === "" ||
                    datosPersonales.telefono === "" || !correoPersonalValido || datosEmpresa.razonSocial === "" 
                )
            ) 
            {
                if (datosEmpresa.razonSocial === "") datosFaltantes.push("razón social");
                if (dayjs(datosEmpresa.fechaConstitucion).format('DD-MM-YYYY') === dayjs().startOf('day').format('DD-MM-YYYY')) datosFaltantes.push("fecha de constitución")
                if (datosEmpresa.direccionFisica === "") datosFaltantes.push("direccionFisica")
                if (datosEmpresa.paisOrigen === "" || datosEmpresa.paisOrigen === undefined) datosFaltantes.push("país de origen")
                if (datosEmpresa.actividadEconomica === "" || datosEmpresa.actividadEconomica === undefined) datosFaltantes.push("actividad económica")
                if (datosEmpresa.identificacion === "") datosFaltantes.push("número de identificación en datos de la empresa");
                if (datosEmpresa.tipoIdentificacion === "31" && datosEmpresa.dv === "") datosFaltantes.push("dígito de verificación");
                if (datosPersonales.nombres === "") datosFaltantes.push("nombres");
                if (datosPersonales.apellidos === "") datosFaltantes.push("apellidos");
                if (datosPersonales.identificacion === "") datosFaltantes.push("número de identificación en datos del usuario");
                if (datosPersonales.telefono === "") datosFaltantes.push("telefono");
                if (datosPersonales.correo === "") datosFaltantes.push("correo corporativo");
                if (datosPersonales.correo !== "" && !correoPersonalValido) datosFaltantes.push("el correo ingresado no es válido");
                const faltantesJoin = datosFaltantes.join(', ');
                setEnunciadoDatosFaltantes("Para diligenciar por completo los datos generales y poder continuar, por favor revise los siguientes campos: ");
                setDatosFaltantes(faltantesJoin);
                setDebeValidarDatos(true);
                setAlertaValidacionActiva(true);
                return false;
            }
        }
        if (activeStep === 1) {
            const correoValidoRepLegal = regexCorreo.test(datosRepresentanteLegal.correo);
            if (datosRepresentanteLegal.nombres === "" ||
                datosRepresentanteLegal.apellidos === "" ||
                datosRepresentanteLegal.identificacion === "" ||
                datosRepresentanteLegal.telefono === "" ||
                !correoValidoRepLegal) {
                if (datosRepresentanteLegal.nombres === "") datosFaltantes.push("nombres");
                if (datosRepresentanteLegal.apellidos === "") datosFaltantes.push("apellidos");
                if (datosRepresentanteLegal.identificacion === "") datosFaltantes.push("número de identificación");
                if (datosRepresentanteLegal.telefono === "") datosFaltantes.push("telefono");
                if (datosRepresentanteLegal.correo === "") datosFaltantes.push("correo corporativo");
                if (datosRepresentanteLegal.correo !== "" && !correoValidoRepLegal) datosFaltantes.push("el correo ingresado no es válido");

                setEnunciadoDatosFaltantes("Para diligenciar por completo los datos del representante legal y poder continuar, por favor revise los siguientes campos: ")
                const faltantesJoin = datosFaltantes.join(', ');
                setDatosFaltantes(faltantesJoin);
                setDebeValidarDatos(true);
                setAlertaValidacionActiva(true);
                return false;
            }
        }
        if (activeStep === 2) {
            if (datosBancarios.banco === 0 ||
                datosBancarios.cuenta === "" ||
                (datosBancarios.tipoCuenta !== 1 && datosBancarios.tipoCuenta !== 2) ||
                datosBancarios.certificacionBancaria === '' ||
                ((datosBancariosRenderizados === 2 || datosBancariosRenderizados === 3) && datosBancarios2.banco === 0) ||
                ((datosBancariosRenderizados === 2 || datosBancariosRenderizados === 3) && datosBancarios2.cuenta === "") ||
                ((datosBancariosRenderizados === 2 || datosBancariosRenderizados === 3) && (datosBancarios2.tipoCuenta !== 1 && datosBancarios2.tipoCuenta !== 2) ||
                    ((datosBancariosRenderizados === 2 || datosBancariosRenderizados === 3) && datosBancarios2.certificacionBancaria === '') ||
                    (datosBancariosRenderizados === 3 && datosBancarios3.banco === 0) ||
                    (datosBancariosRenderizados === 3 && datosBancarios3.cuenta === "") ||
                    (datosBancariosRenderizados === 3 && (datosBancarios3.tipoCuenta !== 1 && datosBancarios3.tipoCuenta !== 2)) ||
                    (datosBancariosRenderizados === 3 && datosBancarios3.certificacionBancaria === '')
                )) {
                if (datosBancarios.banco === 0) datosFaltantes.push("banco");
                if ((datosBancarios.tipoCuenta !== 1 && datosBancarios.tipoCuenta !== 2)) datosFaltantes.push("tipo de cuenta");
                if (datosBancarios.cuenta === "") datosFaltantes.push("número de cuenta");
                if (datosBancarios.certificacionBancaria === "") datosFaltantes.push("certificación bancaria");
                if ((datosBancariosRenderizados === 2 || datosBancariosRenderizados === 3) && datosBancarios2.banco === 0) datosFaltantes.push("banco de la segunda cuenta")
                if ((datosBancariosRenderizados === 2 || datosBancariosRenderizados === 3) && datosBancarios2.cuenta === "") datosFaltantes.push("número de la segunda cuenta")
                if ((datosBancariosRenderizados === 2 || datosBancariosRenderizados === 3) && (datosBancarios2.tipoCuenta !== 1 && datosBancarios2.tipoCuenta !== 2)) datosFaltantes.push("tipo de la segunda cuenta")
                if ((datosBancariosRenderizados === 2 || datosBancariosRenderizados === 3) && datosBancarios2.certificacionBancaria === '') datosFaltantes.push("certificación bancaria de la segunda cuenta")
                if (datosBancariosRenderizados === 3 && datosBancarios3.banco === 0) datosFaltantes.push("banco de la tercera cuenta")
                if (datosBancariosRenderizados === 3 && datosBancarios3.cuenta === "") datosFaltantes.push("número de la tercera cuenta")
                if (datosBancariosRenderizados === 3 && (datosBancarios3.tipoCuenta !== 1 && datosBancarios3.tipoCuenta !== 2)) datosFaltantes.push("tipo de la tercera cuenta")
                if (datosBancariosRenderizados === 3 && datosBancarios3.certificacionBancaria === '') datosFaltantes.push("certificación bancaria de la tercera cuenta")
                const faltantesJoin = datosFaltantes.join(', ');
                setEnunciadoDatosFaltantes("Para diligenciar por completo los datos bancarios y poder continuar, por favor revise los siguientes campos: ");
                setDatosFaltantes(faltantesJoin);
                setDebeValidarDatos(true);
                setAlertaValidacionActiva(true);
                return false;
            }
        }
        if (activeStep === 3) {
            if (datosAnexos.camaraDeComercio === "" ||
                datosAnexos.identificacionRepLegal === "" ||
                datosAnexos.rut === "" ||
                datosAnexos.composicionAccionaria === "" ||
                !datosAnexos.terminosCesion) {
                if (datosAnexos.rut === "") datosFaltantes.push("RUT");
                if (datosAnexos.composicionAccionaria === "") datosFaltantes.push("composición accionaria");
                if (datosAnexos.camaraDeComercio === "") datosFaltantes.push("cámara de comercio");
                if (datosAnexos.identificacionRepLegal === "") datosFaltantes.push("identificación del Rep. Legal");
                if (datosAnexos.estadosFinancieros === "") datosFaltantes.push("estados financieros");
                if (!datosAnexos.terminosCesion) datosFaltantes.push("términos y condiciones");
                const faltantesJoin = datosFaltantes.join(', ');
                setEnunciadoDatosFaltantes("Para diligenciar por completo la sección de anexos y poder continuar, por favor revise: ")
                setDatosFaltantes(faltantesJoin);
                setDebeValidarDatos(true);
                setAlertaValidacionActiva(true);
                return false;
            }
        }
        datosFaltantes.splice(0, datosFaltantes.length);
        setEnunciadoDatosFaltantes("");
        setDebeValidarDatos(false);
        setAlertaValidacionActiva(false);
        return true;
    }, [datosEmpresa, datosPersonales, datosRepresentanteLegal, datosBancarios, datosBancarios2, datosBancarios3, datosAnexos, activeStep, datosBancariosRenderizados]);
    useEffect(() => {
        if (debeValidarDatos) validarDatos();
    }, [datosEmpresa, datosPersonales, datosRepresentanteLegal, datosBancarios, datosBancarios2, datosBancarios3, datosAnexos, debeValidarDatos, datosBancariosRenderizados, validarDatos]);
    const handleGuardar = () => {
        const validarDatosValue = validarDatos();
        if (validarDatosValue) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            const requestData = {
                tenantId: tenantId,
                informacionVinculacion: {
                    id: idVinculacion,
                    tipoDocumento: formData.datosEmpresa.tipoIdentificacion,
                    documento: formData.datosEmpresa.identificacion,
                    razonSocial: formData.datosEmpresa.razonSocial
                },
                data: JSON.stringify(formData)
            };
          fetch(process.env.REACT_APP_CORE_LAMBDA_VINCULACIONES_URL + 'guardeInformacion', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error al guardar la información');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.succeeded) {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    } else {
                        setActiveStep((prevActiveStep) => prevActiveStep + 2);
                    }
                    const infoVinculacion = {
                        enviada: true,
                        fecha: new Date(),
                        data: {
                            datosEmpresa,
                            datosPersonales,
                            datosRepresentanteLegal,
                            datosBancarios,
                            datosBancarios2,
                            datosBancarios3,
                            datosAnexos,
                            datosBancariosRenderizados
                        }
                    }

                    localStorage.setItem('infoVinculacion', JSON.stringify(infoVinculacion))
                })
                .catch(error => {
                    console.error('Error al guardar la información:', error);
                    setActiveStep((prevActiveStep) => prevActiveStep + 2);
                });
        }

    };

    const GuardarInfo = () => {
        const infoVinculacion = {
            enviada: false,
            fecha: new Date(),
            data: {
                datosEmpresa,
                datosPersonales,
                datosRepresentanteLegal,
                datosBancarios,
                datosBancarios2,
                datosBancarios3,
                datosAnexos,
                datosBancariosRenderizados
            },
        }
        SaveDataVinculacion('infoVinculacion', infoVinculacion)
    }

    return (
        <ThemeProvider theme={theme}>
            <Paper elevation={3} sx={{
                width: isMobile ? '95vw' : (isDesktop ? '75vw' : (isLargeDesktop ? '65vw' : '50vw')),
                m: isMobile ? 5 : (isDesktop ? 5 : (isLargeDesktop ? 2 : 5)),
                p: isMobile ? 5 : (isDesktop ? 5 : (isLargeDesktop ? 4 : 5)),
                display: 'flex',
                flexDirection: 'column',
            }} >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {(!isMobile && (activeStep !== 4) && (activeStep !== 5) && (activeStep !== 6)) && (
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            sx={{
                                width: '85%',
                                '& .MuiStepIcon-root': { width: '51px', height: '51px' },
                                '& .MuiStepConnector-root': { margin: '12px', }
                            }}
                            connector={<StepConnector sx={{ bgcolor: 'transparent' }} />}
                        >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )}
                    {(isMobile && (activeStep !== 4) && (activeStep !== 5) && (activeStep !== 6)) && (
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            sx={{
                                // width: '85%',
                                '& .MuiStepIcon-root': { width: '40px', height: '40px' },
                                '& .MuiStepConnector-root': { margin: '7px' }
                            }}
                            connector={<StepConnector sx={{ bgcolor: 'transparent' }} />}
                        >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel></StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )}
                </Box>
                <Box sx={{ p: isMobile ? 0 : 2, flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                        <Box sx={{ width: isMobile ? '100%' : '85%' }}>
                            {activeStep === 0 && <DatosEmpresaForm datosEmpresa={datosEmpresa} setDatosEmpresa={setDatosEmpresa} />}
                            {activeStep === 0 && <DatosUsuarioForm datosPersonales={datosPersonales} setDatosPersonales={setDatosPersonales} />}
                            {activeStep === 1 && <DatosRepresentanteLegalForm datosPersonales={datosPersonales} datosRepresentanteLegal={datosRepresentanteLegal} setDatosRepresentanteLegal={setDatosRepresentanteLegal} />}
                            {activeStep === 2 && <DatosBancariosFormRender
                                datosBancarios={datosBancarios}
                                datosBancarios2={datosBancarios2}
                                datosBancarios3={datosBancarios3}
                                setDatosBancarios={setDatosBancarios}
                                setDatosBancarios2={setDatosBancarios2}
                                setDatosBancarios3={setDatosBancarios3}
                                idVinculacion={idVinculacion}
                                datosEmpresa={datosEmpresa}
                                datosBancariosRenderizados={datosBancariosRenderizados}
                                setDatosBancariosRenderizados={setDatosBancariosRenderizados} />}
                            {activeStep === 3 && <InputFileUpload datosAnexos={datosAnexos} setDatosAnexos={setDatosAnexos} idVinculacion={idVinculacion} />}
                            {activeStep === 4 && <SendingInformation />}
                            {activeStep === 5 && <InformationSent />}
                            {activeStep === 6 && <ErrorSendingInformation />}
                        </Box>
                    </Box>
                </Box>
                {(alertaValidacionActiva) && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3, p: isMobile ? 0 : 2 }}>
                        <Box sx={{ width: isMobile ? '100%' : '85%' }}>
                            <Alert severity="info">{enunciadoDatosFaltantes} {datosFaltantes}.</Alert>
                        </Box>
                    </Box>)}
                <Box sx={{ display: 'flex', justifyContent: 'center', p: isMobile ? 0 : 2 }}>
                    <Grid container justifyContent="space-between" sx={{ width: isMobile ? '100%' : '85%' }}>
                        <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'start' }}>
                            {(activeStep !== 0 && activeStep !== 4 && activeStep !== 5 && activeStep !== 6) && (
                                <Button
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                    variant="outlined"
                                >
                                    Anterior
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'end', pr: isMobile ? 0 : 1 }}>
                            {(activeStep !== steps.length - 1 && (activeStep !== 4) && (activeStep !== 5) && (activeStep !== 6)) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                >
                                    Siguiente
                                </Button>
                            )}
                            {(activeStep === steps.length - 1 && (activeStep !== 4) && (activeStep !== 5) && (activeStep !== 6)) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGuardar}
                                >
                                    Guardar
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </ThemeProvider >
    );
}
