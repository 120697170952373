import * as React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Typography, useMediaQuery, useTheme, Autocomplete, Box, TextField } from '@mui/material';
import { PropsDatosEmpresa } from '../../shared/VinculacionesModels';
import { DatePicker } from '@mui/x-date-pickers';
import countries from '../../shared/countries.json'
import actividadesEconomicas from '../../shared/actividadesEconomicas.json'
import dayjs, { Dayjs } from 'dayjs';
import { SyntheticEvent } from 'react';

const DatosEmpresaForm: React.FC<PropsDatosEmpresa> = ({ datosEmpresa, setDatosEmpresa }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setDatosEmpresa({
            ...datosEmpresa,
            [name]: value
        });
    };
    const handleDateChange = (newValue: Dayjs | null) => {
        if (newValue) {
            const formattedDate = newValue.startOf('day');
            setDatosEmpresa({
                ...datosEmpresa,
                fechaConstitucion: formattedDate
            });
        }
    };
    const handleChangeCountries = (event: SyntheticEvent<Element, Event>, newValue: { code: string; label: string; phone: string; suggested?: undefined; } | { code: string; label: string; phone: string; suggested: boolean; } | null) => {
        setDatosEmpresa({
            ...datosEmpresa,
            paisOrigen: newValue?.code
        });
    };

    const handleChangeActivities = (event: SyntheticEvent<Element, Event>, newValue: { id: string; label: string} | null) => {
        setDatosEmpresa({
            ...datosEmpresa,
            actividadEconomica: newValue?.id
        });
    };

    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Datos de la Empresa
            </Typography>
            <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12} md={12} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Razón social"
                        variant="outlined"
                        value={datosEmpresa.razonSocial}
                        onChange={handleChange}
                        name="razonSocial"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <DatePicker
                        sx={{ minWidth: "100%" }}
                        label="Fecha de constitución"
                        value={dayjs(datosEmpresa.fechaConstitucion)}
                        onChange={handleDateChange}
                        maxDate={dayjs()}
                        format='DD/MM/YYYY'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <Autocomplete
                        sx={{ minWidth: "100%" }}
                        options={countries}
                        autoHighlight
                        onChange={handleChangeCountries}
                        value={countries.filter(x => x.code == datosEmpresa.paisOrigen).length > 0 ? countries.filter(x => x.code == datosEmpresa.paisOrigen)[0] : null}
                        getOptionLabel={(option) => option.label || ''}
                        renderOption={(props: any, option: any) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box
                                    key={key}
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...optionProps}
                                >
                                    <img
                                        loading="lazy"
                                        width="20"
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        alt=""
                                    />
                                    {option.label} ({option.code})
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="País de origen"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Dirección física"
                        variant="outlined"
                        value={datosEmpresa.direccionFisica}
                        onChange={handleChange}
                        name="direccionFisica"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <Autocomplete
                        sx={{ minWidth: "100%" }}
                        options={actividadesEconomicas}
                        autoHighlight
                        onChange={handleChangeActivities}
                        value={actividadesEconomicas.filter(x => x.id == datosEmpresa.actividadEconomica).length > 0 ? actividadesEconomicas.filter(x => x.id == datosEmpresa.actividadEconomica)[0] : null}
                        getOptionLabel={(option) => option.label || ''}
                        renderOption={(props: any, option: any) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box
                                    key={key}
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...optionProps}
                                >
                                    {option.id} - {option.label}
                                </Box>
                            );
                        }}
                        renderInput={(params) => <TextField {...params} label="Actividad económica" />}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <FormControl fullWidth sx={{ textAlign: 'left' }}>
                        <InputLabel id="identification-type-label">Tipo de Identificación</InputLabel>
                        <Select
                            labelId="identification-type-label"
                            id="identification-type"
                            value={datosEmpresa.tipoIdentificacion}
                            label="Tipo de Identificación"
                            name='tipoIdentificacion'
                            onChange={handleChange}
                        >
                            <MenuItem value={31}>NIT</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {datosEmpresa.tipoIdentificacion === "31" && (
                    <>
                        <Grid item xs={9} md={4} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                            <TextField
                                fullWidth
                                label="Número de identificación"
                                variant="outlined"
                                value={datosEmpresa.identificacion}
                                onChange={handleChange}
                                name="identificacion"
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={3} md={2} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                            <TextField
                                fullWidth
                                label="DV"
                                value={datosEmpresa.dv}
                                onChange={handleChange}
                                variant="outlined"
                                name="dv"
                                type='number'
                            />
                        </Grid>
                    </>
                )}
                {datosEmpresa.tipoIdentificacion !== "31" && (
                    <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                        <TextField
                            fullWidth
                            label="Número de identificación"
                            variant="outlined"
                            value={datosEmpresa.identificacion}
                            onChange={handleChange}
                            name="identificacion"
                            type='number'
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default DatosEmpresaForm;
